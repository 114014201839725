/* CSS */
.button-92 {
  --c: #ffffff;
  /* text color */
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%)
      var(--_p, 100%) / 300% no-repeat,
    #4575e6;
  /* background color */
  color: #03030300;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
  text-shadow: calc(var(--_i, -1) * 0.08em) -0.01em 0 var(--c),
    calc(var(--_i, -1) * -0.08em) 0.01em 2px #0004;
  outline-offset: 0.1em;
  transition: 0.3s;
}

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.ck-editor__editable {
  border: 1px solid #ced4da; /* Same as .form-control border */
  border-radius: 0.25rem; /* Same as .form-control border radius */
  padding: 0.375rem 0.75rem; /* Adjust padding to match form control */
  min-height: 150px; /* Match your textarea's height */
  background-color: #fff;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9Q rgba(230, 219, 219, 0.333);
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
  padding: 0.1em 0.8em;
}

/* expiry button */
/* CSS */
.button-91 {
  --c: #fff;
  /* text color */
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%)
      var(--_p, 100%) / 300% no-repeat,
    #ff6600;
  /* background color */
  color: #0000;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
  text-shadow: calc(var(--_i, -1) * 0.08em) -0.01em 0 var(--c),
    calc(var(--_i, -1) * -0.08em) 0.01em 2px #0004;
  outline-offset: 0.1em;
  transition: 0.3s;
}

.button-91:hover,
.button-91:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-91:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9Q #0005;
  transition: 0s;
}

.button-91 {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding: 0.1em 0.8em;
  margin: 10px;
}
