.productmodal {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #00000038;
    position: fixed;
    z-index: 999;
}

.productmodalcontent {
    position: absolute;
    background-color: #fff;
    /* height: 30px; */
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    padding: 20px;
    border-radius: 10px;
}

.prdcttctx {
    color: #000;
    font-Size: 24px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    padding-bottom: 10px;
}

.unjktxtt {
    color: #585454;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 18px;

}

.divtstxmodal {
    padding: 10px 0px;
}

.prcdgimgj {
    padding: 20px;
    width: 100%;
    height: 300px;
}

.prcdgimgj img {
    width: 100%;
    height: 100%;
}

.icklohjj {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* qr modal */

.QRsharemodalSec {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

.qrshareInn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 500px;
    border-radius: 9px;
    border: 1px solid #CCC8C8;
    background: #FBFBFB;
    padding: 50px 20px;
}

.ModClosebtn {
    position: absolute;
    top: 5px;
    right: 10px;
}

.modQRimg {
    width: 200px;
    height: 200px;
    margin: 15px auto;
}

.modQRimg img {
    width: 100%;
    height: 100%;
}

.qrDwnld {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #009E60;
    height: 40px;
    padding: 10px 30px;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1px solid #009E60;
    outline: 0;
    margin-bottom: 15px;
    transition: all 0.3s ease;
}

.qrDwnld:hover {
    color: #fff;
    border: 1px solid #009E60;
    color: #009E60;
    background-color: transparent;
}

.modshareBtn {
    display: block;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #ADADAD;
    height: 40px;
    padding: 10px 30px;
    background: transparent;
    color: #010203;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    transition: all 0.3s ease;
}

.modshareBtn:hover {
    border: 1px solid #ADADAD;
    background-color: #474646;
    color: #fff;
}

.modshareBtn i {
    margin-left: 5px;
    font-size: 14px;
}

/* profile share modal */

.prflShrModSec {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.prflshrModInn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 600px;
    border-radius: 9px;
    border: 1px solid #CCC8C8;
    background: #FBFBFB;
    padding: 50px 40px;
}

.prflShrIcon {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 25px;
}

.prflshrDiv {
    width: 30%;
}

.prflshrFig {
    width: 50px;
    height: 50px;
    margin: 0px auto;
}

.prflshrFig img {
    width: 100%;
    height: 100%;
}

.prflshrnm {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
}

.copyUrlDiv {
    width: 100%;
    border-radius: 15px;
    background: #D9D9D9;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.copyUrlBtn {
    width: 20%;
    border-radius: 10px;
    background: #009E60;
    height: 40px;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border: none;
    outline: 0;
    gap: 10px;
}

.copyUrlBtn:hover {
    background: #585454;
    color: #fff;
}

.copyUrlLink {
    width: 80%;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 20px;
    word-break: break-word;
    overflow-wrap: break-word;
}

.copyUrlLink:hover {
    color: #000;
}

/* Responsive */

@media (max-width: 767px) {

    .productmodalcontent {
        width: 90%;
    }

    .prcdgimgj {
        padding: 15px 10px;
    }

    .prdcttctx {
        font-Size: 20px;
    }

    .unjktxtt {
        font-size: 13px;
    }

    .prflshrModInn {
        width: 85%;
    }

    .prflshrFig {
        width: 35px;
        height: 35px;
    }

    .prflshrnm {
        font-size: 14px;
    }

    .copyUrlDiv {
        height: 50px;
        margin-bottom: 0px;
    }

    .copyUrlBtn {
        height: 30px;
        padding: 5px 15px;
        font-size: 13px;
    }

    .copyUrlLink {
        font-size: 13px;
        padding-left: 5px;
    }

    .prflshrModInn {
        padding: 40px 15px;
    }
}

@media (max-width: 575px) {

    .prcdgimgj {
        height: 250px;
    }

    .modQRimg {
        width: 150px;
        height: 150px;
    }

    .qrshareInn {
        padding: 30px 20px;
        width: 90%;
    }

    .qrDwnld,
    .modshareBtn {
        height: 35px;
        padding: 10px 22px;
        font-size: 13px;
    }

    .prflshrModInn {
        width: 90%;
    }

    .prflshrnm {
        font-size: 12px;
    }

    .prflshrFig {
        width: 25px;
        height: 25px;
    }

    .copyUrlDiv {
        padding: 10px 5px;
        margin-top: 30px;
        margin-bottom: 0px;
    }
}