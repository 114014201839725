
/* input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
} */

.hideNoChoosen{
    position: absolute;
    left: 294px;
    width: 104px;
    opacity: 0;
    cursor: pointer;
}