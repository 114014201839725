* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.memberownprfl {
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: rgba(237, 238, 255, 1);
    padding-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.cust_container {
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
}

.brdrsrvc {
    border: 2px solid #DBDEFE;
    margin: 15px 20px 0px;
    border-radius: 10px;
    padding: 10px 15px;
}

.mainheader {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.mainheader.fixed {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    right: 0;
    animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.TopHeader {
    /* background-color: #FAFAFA; */
    background-color: #67308F;
    padding: 15px 0px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.headerlogocnctmain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 0px 20px; */
}

.homeneoo {
    animation: diagonalAnimation 1.5s ease-out;
    transform-origin: bottom right;
}

@keyframes diagonalAnimation {
    0% {
        transform: translate(90%, 100%);
    }

    100% {
        transform: translateY(0, 0);
    }
}

.logoneeo {
    width: 150px;
    /* height: 40px; */
    height: auto;
    margin: 0;
}

.logoneeo img {
    width: 100%;
    height: 100%;
}

.iconmainheader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}

.imgprfls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    position: relative;
    margin-left: 15px;
    cursor: pointer;
}

.userDrop {
    position: absolute;
    bottom: -143px;
    right: 0;
    background-color: #fff;
    width: 130px;
    padding: 5px 0px;
    box-shadow: 0px 0px 5px 0px #ccc;
    z-index: 25;
    border-radius: 4px;
}

.userMenu {
    list-style-type: none;
}

.userMenu li {
    border-bottom: 2px solid rgb(209, 207, 207);
}

.userMenu li:last-child {
    border: none;
}

.userMenu li a {
    display: block;
    width: 100%;
    padding: 5px 8px;
    font-size: 13px;
    color: #000;
    font-weight: 500;
}

.userMenu li a:hover {
    background-color: #6A08B7;
    color: #fff;
}

.iconarrw i {
    font-size: 15px;
    color: #d7caca;
    margin-left: 10px;
}

.prflimg {
    width: 41px;
    height: 40px;
}

.prflimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: centers;
}

#QRIcon {
    display: none;
}

.iconhdmin {
    width: 30px;
    height: 23px;
    margin: 0;
    cursor: pointer;
}

.iconhdmin svg {
    width: 100%;
    height: 100%;
    /* fill: #d7caca; */
    fill: #fff;
}

.iconhdmin svg:hover {
    fill: #d7caca !important;
}

.iconhdmin i {
    font-size: 18px;
    color: #fff;
}

.iconhdmin img {
    width: 100%;
    height: 100%;
}

.dirctrIcon {
    width: 12px;
    height: 12px;
}

.dirctrIcon img {
    width: 100%;
    height: 100%;
}

.fottersection {
    width: 100%;
    height: auto;
    /* padding: 30px 0px; */
}

.csttxt {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-height: 20px;
    padding-bottom: 5px;
}

/* fixed footer */

.FixedFootSec {
    width: 100%;
    height: auto;
    padding: 15px 0px;
    /* background: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1)); */
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: none;
    border: 1px solid #ECE7E7;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.FixFootMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2%;
}

.footMenuIcon {
    width: 22px;
    height: 22px;
}

.footMenuIcon svg {
    width: 100%;
    height: 100%;
    fill: #000;
}

.footMenuIcon svg:hover,
.footMenuIcon svg:focus {
    fill: #6A08B7 !important;
}

.fopterbg {
    width: 100%;
    /* background-color: rgba(250, 250, 250, 1); */
    height: 100%;
    padding: 40px 30px 85px;
}

.imgfooterprl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.grpimg {
    width: 50px;
    height: 52px;
}

.grpimg img {
    width: 100%;
    height: 100%;
}

.fotercfyvflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.txttftrtkjh {
    color: #585454;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.24px;
    line-height: 18px;
    padding: 10px 0px;
}

.txtppcpy {
    color: #585454;
    font-Size: 12px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.24px;

}

.bgghjh {
    background-color: #F0EAF4;
    width: 100%;
    height: auto;
    /* position: absolute;
    z-index: 20;
    top: 80px; */
    box-shadow: 0px 0px 4px 0px #ccc;
    padding: 30px 0px;
}

.inpyuj {
    width: 100%;
    padding: 10px 0px;
    position: relative;
}

.inpyuj input {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    outline: 0;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    color: #010203;
    font-size: 15px;
    border: 1px solid #E1D6E9;
}

.inpyuj input::placeholder {
    color: #010203;
}

.ghjhhsrch i {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    color: grey;
}

.fhjsdcncth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.mnakmsjjmn {
    width: 100%;
    max-width: 70%;
    /* margin: 10px 0px; */
}

.ttxytnk {
    /* color: #FAFAFA; */
    color: #010203;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.ttxytnk i {
    margin-left: 5px;
    color: #010203;
    font-size: 15px;
}

.ttxytnkactc {
    padding: 10px;
    border: 1px solid #010203;
    border-radius: 18px;
    text-align: center;
    cursor: pointer;
}

.ttxytnkactc i {
    margin-left: 5px;
    color: #010203;
    font-size: 15px;
}

.authhbg {
    background-color: #67308fa1;
    width: 100%;
    height: 100vh;
    position: relative;
}

.backhjhjlgn {
    width: 100%;
    /* height: 100%; */
    background-color: #fff;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 50%;
    border-radius: 10px;
}

.lgntxtpp {
    font-size: 40px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    letter-Spacing: 0.24px;
}

/* login page start */

.LoginPage {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #EDEEFF;
}

.LoginBgOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6));
    z-index: 1;
}

.LoginForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    max-width: 400px;
    width: 100%;
    z-index: 2;
}

.LoginTop {
    background: #67308F;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgb(187 89 235 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    border-radius: 0.5rem;
    margin: -50px 0px 30px;
    padding: 25px 20px;
    text-align: center;
    color: #fff;
}

.LoginHead {
    font-size: 25px;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    margin-bottom: 15px;
}

.LoginTopIcon {
    margin-bottom: 10px;
}

.LoginTopIcon i {
    margin: 0px 12px;
    font-size: 15px;
}

.LoginBtm form {
    padding: 15px 0px;
}

.LoginBtm .form-control:focus {
    border-color: #c9c9c9;
    box-shadow: none;
}

.LoginBtm input {
    width: 100%;
    display: block;
    height: 40px;
    border: none;
    outline: 0;
    border: 1px solid #c9c9c9;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
}

.LoginBtm input::placeholder {
    color: #5c5959;
}

.LoginBtm input[type='checkbox'] {
    width: 13px;
    height: 13px;
    display: inline-block;
}

.LoginRem {
    font-size: 15px;
    margin-left: 5px;
    color: #5c5959;
}

.LoginBtn {
    width: 100%;
    height: 36px;
    /* background-color: rgb(26, 115, 232); */
    background-color: #67308F;
    color: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: rgba(26, 115, 232, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(26, 115, 232, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(26, 115, 232, 0.15) 0rem 0.0625rem 0.3125rem 0rem;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.LoginLink {
    text-align: center;
    color: rgb(123, 128, 154);
    margin-bottom: 15px;
    font-size: 15px;
}

.LoginLink a {
    color: rgb(26, 115, 232);
    font-weight: 500;
    font-size: 15px;
}

.LoginLink a:hover {
    color: rgb(26, 115, 232);
    text-decoration: none;
}

svg:hover {
    fill: #000 !important;
}

/* login page end */

/* Responsive search bar */

.ResSerBar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 60px;
    z-index: 9;
    background-color: #fff;
    border: 1px solid #E1DFDF;
    padding: 10px 0px;
}

.resSerInp {
    width: 100%;
    padding: 10px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.resSerInp input {
    width: 100%;
    padding: 10px;
    background-color: #D9D9D9;
    outline: 0;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    color: #010203;
    font-size: 15px;
    border: 1px solid #E1D6E9;
}

.resSerInp input::placeholder {
    color: #010203;
}

.ressrch i {
    width: 10%;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #000;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.SerOPtn {
    display: flex;
    align-items: center;
    padding-top: 5px;
    gap: 10px;
}

.SerOptnDiv {
    border: 1px solid #D3B0FF;
    border-radius: 10px;
    Width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
}

/* loader design */

.loaderSec {
    width: 100%;
    height: 100vh;
    position: relative;
}

.loadermid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loaderFig {
    width: 250px;
    height: auto;
}

.loaderFig img {
    width: 100%;
    height: 100%;
}

.loaderbtm {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.loadersmlltxt {
    color: #000;
    font-size: 14px;
}

.loaderBigtxt {
    font-size: 22px;
    color: #000;
    font-weight: 500;
}

.loadprogress {
    background-color: #c9c9c9;
    height: 4px;
    margin-top: 20px;
    width: 90%;
}

.loadprogressbar {
    background-color: #634fe0;
}

/* Responsive */

@media (max-width: 1024px) {
    .mnakmsjjmn {
        max-width: 100%;
    }
}

@media (max-width: 991px) {

    /* loader */

    .loaderFig {
        width: 150px;
    }

    .loaderBigtxt {
        font-size: 18px;
    }


    .cust_container {
        max-width: 90%;
    }

    .fopterbg {
        padding: 30px 0px;
        padding-bottom: 80px;
    }

    .bgghjh {
        padding: 15px 0px;
    }
}

@media (max-width: 767px) {
    .iconhdmin {
        width: 25px;
        height: 20px;
    }

    .iconmainheader {
        gap: 20px;
    }

    .imgprfls {
        margin: 0;
    }

    .prflimg {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 575px) {

    /* body{
      background-color: #fff;
    } */

    .iconmainheader {
        align-items: baseline;
    }

    .FixedFootSec {
        display: block;
    }

    .fottersection {
        margin-bottom: 50px;
    }

    .txtppcpy {
        text-align: center;
        margin-top: 15px;
    }

    #headSerIcon,
    #headDirctryIcon {
        display: none;
    }

    #QRIcon {
        display: block;
    }

    .iconarrw i {
        display: none;
    }

    /* login page start */

    .logoneeo {
        width: 120px;
        /* height: 25px; */
    }

    .LoginForm {
        max-width: 75%;
    }

    .LoginBtm form {
        padding: 10px 0px;
    }

    .LoginTop {
        padding: 20px 15px;
        margin-bottom: 15px;
    }

    .LoginHead {
        font-size: 22px;
    }

    .LoginBtm input::placeholder {
        font-size: 14px;
    }

    .LoginBtm input {
        font-size: 14px;
    }

    .LoginRem {
        font-size: 14px;
    }

    .LoginBtm input[type='checkbox'] {
        width: 12px;
        height: 12px;
    }

    .LoginLink {
        font-size: 14px;
    }

    .LoginLink a {
        font-size: 14px;
    }

    .LoginBtn {
        font-size: 13px;
    }

    .fotercfyvflx {
        flex-direction: column;
        align-items: flex-start;
    }

    .txttftrtkjh {
        margin-bottom: 10px;
        padding: 0;
    }

    .imgfooterprl {
        margin-bottom: 15px;
    }

    /* login page end */

    .ttxytnk i,
    .ttxytnkactc i {
        font-size: 12px;
    }

    .ttxytnk,
    .ttxytnkactc {
        font-size: 14px;
        padding: 5px;
    }
}

@media (max-width: 480px) {
    .fhjsdcncth {
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 25px;
    }

    .ttxytnk,
    .ttxytnkactc {
        width: auto;
        font-size: 13px;
    }

    .inpyuj input {
        padding: 6px 10px;
        font-size: 13px;
    }

    .ghjhhsrch i {
        right: 22px;
        font-size: 13px;
    }

    .inpyuj {
        padding: 5px 0px;
    }

}

@media (max-width: 420px) {

    .SerOPtn {
        gap: 7px;
    }

    .SerOptnDiv {
        Width: auto;
        font-size: 13px;
        padding: 0px 12px;
    }

    .logoneeo {
        width: 100px;
    }

    .iconhdmin i {
        font-size: 15px;
    }

    .iconmainheader {
        gap: 18px;
    }

    /* Login page start */

    .LoginForm {
        padding: 20px 15px;
    }

    .LoginHead {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .LoginTop {
        padding: 10px 15px;
    }

    .LoginBtm input {
        height: 35px;
    }

    .LoginLink {
        margin-bottom: 5px;
    }

    .LoginTopIcon i {
        margin: 0px 8px;
        font-size: 13px;
    }

    /* Login page end */
}

/* Responsive */

.homepageHeader {
    width: 100%;
    height: auto;
    padding: 30px 0px;
    border-bottom: 1px solid #B6A8A8;
}

.homeHeadInn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.homeMenu {
    display: flex;
    gap: 40px;
}

.homeJoinBtn {
    height: 45px;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    color: #FBF0F0;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    background: linear-gradient(to left, #6A08B7 50%, rgb(139 158 209) 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
}

.homeJoinBtn:hover {
    background-position: left;
    color: #FBF0F0;
}

.homeactvBtn {
    height: 45px;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border-radius: 16px;
    border: 1px solid #655C5C;
    background: #FFF;
    color: #655C5C;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    background: linear-gradient(to left, #fff 50%, rgb(139 158 209) 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
}

.homeactvBtn:hover {
    background-position: left;
    color: #FBF0F0;
}

.homeBtm {
    width: 100%;
    height: auto;
    padding-top: 30px;
    position: relative;
}

.homeDiv {
    display: flex;
    justify-content: space-between;
}

.homeLeft {
    width: 60%;
}

.Homefig {
    position: absolute;
    right: 0;
    max-width: 420px;
    width: 40%;
    height: 500px;
}

.Homefig img {
    width: 100%;
    height: 100%;
}

.homeContent {
    width: 60%;
}

.homebighead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.2px;
    margin-bottom: 30px;
}

.homeSmallHead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.homepara {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
}

.homeForm {
    padding-top: 40px;
    width: 70%;
}

.homeformHead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.75px;
    margin-bottom: 20px;
}

.homeForm input {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    background: #D9D9D9;
    color: #4B4949;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.45px;
    border: none;
    outline: 0;
    padding: 3px 15px;
}

.homeForm input:-webkit-autofill,
.homeForm input:-webkit-autofill:hover,
.homeForm input:-webkit-autofill:focus,
.homeForm input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.homeForm input[type='checkbox'] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    accent-color: #6A08B7;
}

.HomeFormFlex {
    display: flex;
    justify-content: space-between;
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.39px;
}

.HomefrgtPwd {
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.39px;
}

.HomefrgtPwd:hover {
    color: #777;
}

.passDiv {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: #D9D9D9;
}

.passEyebtn {
    border: none;
    outline: 0;
    background-color: transparent;
    padding-right: 15px;
}

.passEyebtn i {
    color: #151515;
    font-size: 16px;
}

.homeloginBtn {
    width: 100px;
    height: 40px;
    border: none;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #009E60;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    transition: all 0.5s ease;
}

.homeloginBtn:hover {
    color: #009E60;
    border: 1px solid #009E60;
    background-color: #FFF;
}

.homeStruc {
    position: absolute;
    right: -200px;
    height: auto;
}

.homeStrucInn {
    background: #0052A4;
    opacity: 0.22;
    width: 550px;
    height: 500px;
    border-radius: 50%;
}

.HomeFigOne {
    position: absolute;
    left: -40px;
    top: 252px;
    width: 280px;
    height: auto;
    z-index: 3;
}

.HomeFigTwo {
    position: absolute;
    left: -40px;
    top: 55px;
    width: 280px;
    height: auto;
    z-index: 2;
}

.HomeFigThree {
    position: absolute;
    left: 28px;
    top: 50px;
    width: 320px;
    height: auto;
    z-index: 1;
}

.homimg img {
    width: 100%;
    height: 100%;
}

/* Responsive */

@media (max-width: 1399px) {
    .homepageHeader {
        padding: 15px 0px;
    }

    .homeJoinBtn,
    .homeactvBtn {
        height: 44px;
        padding: 5px 25px;
        font-size: 16px;
    }

    .homeMenu {
        gap: 20px;
    }
}

@media (max-width: 1199px) {

    .homeBtm {
        height: 100vh;
    }

    .homeLeft {
        width: 50%;
    }

    .homeContent {
        width: 100%;
    }

    .homeForm {
        padding-top: 40px;
        width: 100%;
    }

    .homebighead {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .homeSmallHead {
        font-size: 16px;
    }

    .homeForm {
        padding-top: 25px;
    }

    .Homefig {
        max-width: 350px;
        width: 40%;
        height: 400px;
    }

    .homeformHead {
        font-size: 22px;
    }
}

@media (max-width: 991px) {

    .homeStrucInn {
        width: 450px;
        height: 400px;
    }

    .HomeFigOne {
        width: 200px;
        top: 195px;
    }

    .HomeFigThree {
        left: 0px;
        top: 40px;
        width: 250px;
    }

    .HomeFigTwo {
        width: 200px;
    }

    .homebighead {
        font-size: 25px;
    }

    .homeSmallHead {
        font-size: 14px;
    }

    .homepara {
        font-size: 13px;
    }

    .homeformHead {
        font-size: 20px;
    }
}

@media (max-width: 767px) {

    /* .homeStruc{
        display: none;
    } */

    .homeDiv {
        height: auto;
        flex-direction: column;
        position: static;
    }

    .Homefig {
        display: none;
    }

    .homeBtm {
        height: auto;
    }

    .homeStruc {
        position: relative;
        right: -300px;
        height: auto;
    }

    .homeLeft {
        width: 100%;
        padding-bottom: 30px;
    }

    .homebighead,
    .homeSmallHead {
        margin-bottom: 15px;
    }

    .homeloginBtn {
        width: 100px;
    }
}

@media (max-width: 575px) {

    .homeHeadInn {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .homebighead {
        font-weight: 500;
        color: #8C369B;
    }

    .homeStrucInn {
        width: 400px;
        height: 350px;
    }

    .HomeFigOne {
        width: 160px;
        top: 160px;
    }

    .HomeFigTwo {
        width: 160px;
    }

    .HomeFigThree {
        width: 190px;
    }

    .homeJoinBtn,
    .homeactvBtn {
        height: 28px;
        padding: 5px 10px;
        font-size: 12px;
    }

    .homeMenu {
        gap: 12px;
    }

    .homebighead {
        font-size: 22px;
    }

    .homepara {
        font-size: 10px;
    }

    .homeSmallHead {
        font-size: 12px;
    }

    .homeloginBtn {
        width: 120px;
        height: 35px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {

    .homeStruc {
        right: -250px;
    }

    .HomeFigTwo {
        width: 140px;
        left: -25px;
    }

    .HomeFigOne {
        width: 140px;
        top: 145px;
    }

    .HomeFigThree {
        width: 165px;
    }
}

@media (max-width: 420px) {
    .homeStruc {
        right: -200px;
    }
}

@media (max-width: 360px) {
    .homeStruc {
        right: -155px;
    }
}

.neoobg {
    width: 100%;
    height: 100%;
}

.bg-wht {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    height: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.mainbgsavecontact {
    padding: 20px 0px;
    position: relative;
}

.mainsavecontact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
}

.emojuwavetxt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding-bottom: 5px;
}

.jsmrtxt {
    color: #010203;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 22px;
    /* word-Wrap: 'break-word' */
}

.savecontactbtn {
    color: #fff;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    word-Wrap: 'break-word';
    background-color: rgba(0, 158, 96, 1);
    border: none;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 30px;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;
}

.savecontactbtn:hover {
    color: #fff;
    background: rgb(139 158 209);
}

.cntntimg {
    width: 20px;
    height: 27px;
    margin-left: 10px;
}

.txtdear {
    color: #010203;
    font-Size: 12px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.24px;
    word-wrap: break-word;
    width: 100%;
    max-width: 55%;
    padding-top: 5px;
}

.bggradient {
    /* background-image: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1)); */
    /* padding: 150px 0px; */
    width: 100%;
    height: 400px;
}

.bggradient img {
    width: 100%;
    height: 100%;
}

.persondetailsdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}

.img_profileflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -30px;
    gap: 1%;
    width: 100%;
    margin-left: 25px;
}

.imgbrdr {
    width: 120px;
    height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.imgmain {
    width: 113px;
    height: 113px;
}

.imgmain img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.likespiner {
    border: 1px solid #4c58fc45;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 10px;
    color: #908B8B;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.likespiner:hover {
    color: #908B8B;
}

.likespinerdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;
}

.likespiner img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.whatsppimg,
.callimg {
    width: 60px;
    height: 40px;
}

/* .callimg{
    display: none;
} */

.whatsppimg img,
.callimg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profiledtlstxt {
    color: #010101;
    font-Size: 24px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 30px;
    word-Wrap: break-word;
}

.prodtlmarkettxt {
    color: #010101;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 18px;
    word-Wrap: break-word
}

.infolabbrdr {
    border: 1px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
}

.infoflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
}

.invitebtn {
    background-color: #009E60;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid #009E60;
    font-size: 14px;
    border-radius: 10px;
    transition: all 0.5s ease;
}

.invitebtn:hover {
    border: 1px solid #009E60;
    color: #009E60;
    background-color: #fff;
}

.ocentxt {
    color: #585454;
    font-Size: 28px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    word-Wrap: break-word;
    padding-bottom: 5px;
}

.pcndgtxt {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    word-Wrap: break-word
}

.dbytxtp {
    color: #585454;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 18px;
}

.rctdivimg {
    width: 90px;
    height: 90px;
    cursor: pointer;
}

.rctdivimg img {
    width: 100%;
    height: 100%;
}

.groupdiv {
    padding-left: 20px;
}

.socialdivflx {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
}

.bgsocial {
    background-color: #010101;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.5s ease;
}

.bgsocial:hover {
    background-color: #7f86e9;
    color: #fff;
}

.cntntbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.flxcntnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.getdrctnBtnDiv {
    padding: 12px 0px;
    display: flex;
    justify-content: end;
}

.getdirectionbtn {
    background-color: #009E60;
    color: #fff;
    border-radius: 15px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
}

.getdirectionbtn i {
    display: none;
}

.getdirectionbtn:hover {
    color: #fff;
}

/* .cntncttxt {
    color: #010203;
    font-Size: 28px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    word-Wrap: break-word
} */

.cntncttxt {
    color: #010203;
    font-Size: 28px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
}

.ArrwiconDiv {
    background-color: #4C58FC;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ArrwiconDiv span {
    color: #fff !important;
    font-size: 12px !important;
}

.divcnctflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.brdrdivdtls {
    width: 100%;
    max-width: 32%;
    border: 2px solid #DBDEFE;
    padding: 15px;
    border-radius: 10px;

}

.dibmailflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.envelopdiv {
    width: 100%;
    max-width: 48%;
    border: 2px solid #DBDEFE;
    padding: 15px;
    border-radius: 10px;
}

.divloctin {
    width: 100%;
    max-width: 100%;
    border: 2px solid #DBDEFE;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.txtdtlmn {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;

}

.txtdtlmn i {
    margin-right: 10px;
}

.mapwidth {
    width: 100%;
}

.mapwidth iframe {
    width: 100%;
    border-radius: 10px;
}

.abtbrsdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.txtabtmain {
    color: #585454;
    font-Size: 12px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-height: 16px;
    margin: 10px 0px 0px 0px;
}

.servcbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.srvcboxbrdr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 20px;
    row-gap: 10px;
}

.brdrmaindesign {
    width: 100%;
    max-width: 22%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.brdrmaindesign:hover,
.brdrseo:hover,
.brdrseosms:hover,
.brdrprnt:hover,
.brdrbrand:hover {
    background: #7f86e9;
    color: #fff;
}

.brdrseo {
    width: 100%;
    max-width: 11%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.brdrseosms {
    width: 100%;
    max-width: 17%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}

.brdrprnt {
    width: 100%;
    max-width: 20%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.brdrbrand {
    width: 100%;
    max-width: 20%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.prdrtbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.prdrtflxdivmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1%;
    margin-top: 20px;
}

.brdrprdctdiv {
    border: 1px solid #DBDEFE;
    padding: 10px;
    width: 100%;
    max-width: 24%;
    overflow: hidden;
}

.brdrprdctdiv:hover .prdcgimg img {
    transform: scale(1.15);
}

.txtdiv {
    color: #000000;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-Height: 22px;
    padding: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.txtpara {
    color: #585454;
    font-Size: 10px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.prdcgimg {
    width: 100%;
    height: 150px;
    cursor: pointer;
    overflow: hidden;
}

.prdcgimg img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: 0.5s all ease;
    /* overflow: hidden; */
}

.pdrmainflx {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 15px;
}

.pdfflxDiv {
    width: 30%;
}

.pdfimg {
    width: 120px;
    height: 126px;
    position: relative;
    margin: auto;
}

.pdfimg img {
    width: 100%;
    height: 100%;
}

.inpuduv {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

}

.inpuduv input {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.prfltxtprdct {
    color: #000;
    font-Size: 14px;
    font-Family: 'Roboto';
    font-Weight: 600;
    letter-Spacing: 0.42px;
    text-align: center;
}

.prfldtlsprdct {
    color: #585454;
    font-Size: 14px;
    font-Family: 'Roboto';
    font-Weight: 300;
    letter-Spacing: 0.24px;
    line-height: 18px;
    width: 100%;
    max-width: 60%;
    margin-top: 10px;
    text-align: center;
}

.prflmainflx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
}

/* Responsive */

@media(max-width:1199px) {
    .cntncttxt {
        font-size: 20px;
    }

    .prfldtlsprdct {
        max-width: 100%;
    }

    .pdfimg {
        width: 55px;
        height: 60px;
    }

    .brdrseosms {
        width: 100%;
        max-width: 22%;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 25%;
    }

    .ocentxt {
        font-Size: 20px;
        line-Height: 25px;
    }
}

@media(max-width:991px) {

    .bggradient {
        height: 250px;
    }

    .savecontactbtn {
        font-Size: 14px;
        line-height: 18px;
        padding: 10px 20px;
        white-space: nowrap;
    }

    .txtdtlmn {
        color: #585454;
        font-Size: 14px;
        line-Height: 18px;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 32%;
    }

    .brdrseo {
        width: 100%;
        max-width: 20%;
    }

    .brdrseosms {
        width: 100%;
        max-width: 30%;
    }

    .brdrprnt {
        width: 100%;
        max-width: 24%;
    }
}

@media(max-width:767px) {

    .prdrtflxdivmain {
        row-gap: 10px;
    }

    .brdrprdctdiv {
        max-width: 49%;
    }

    .persondetailsdiv {
        flex-wrap: wrap;
        gap: 15px;
    }

    .img_profileflx {
        margin-left: 0;
    }

    .txtdiv {
        font-Size: 15px;
        padding: 7px 0px;
    }

    .txtdear {
        color: #010203;
        font-Size: 12px;
        max-width: 70%;
        padding-top: 5px;
    }

    .pdfimg {
        width: 40px;
        height: 40px;
    }

    /* .pdfflxDiv {
        width: 47%;
    } */

    .prfldtlsprdct {
        font-Size: 12px;
    }

    .savecontactbtn {
        padding: 8px 20px;
    }

    .imgmain {
        width: 100px;
        height: 100px;
    }

    .imgbrdr {
        width: 105px;
        height: 105px;
    }

    .profiledtlstxt {
        color: #010101;
        font-Size: 20px;
        font-Weight: 500;
        line-Height: 28px;
    }

    .likespiner {
        padding: 7px;
    }

    .likespiner img {
        width: 15px;
        height: 15px;
    }

    .whatsppimg,
    .callimg {
        width: 42px;
        height: 33px;
    }

    .pcndgtxt {
        color: #585454;
        font-Size: 14px;
        line-Height: 21px;
    }

    .txtdtlmn {
        color: #585454;
        font-Size: 14px;
        line-Height: 20px;
    }

    .divcnctflx {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
        column-gap: 10px;
    }

    .brdrdivdtls {
        width: 100%;
        max-width: 48%;
        text-align: center;
    }

    .brdrdivdtls:nth-child(3) {
        width: 100%;
        max-width: 70%;
    }

    .dibmailflx {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 15px;
    }

    .envelopdiv {
        width: 100%;
        max-width: 70%;
        text-align: center;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 49%;
    }

    .brdrmaindesign:nth-child(3) {
        width: 100%;
        max-width: 40%;
    }

    .brdrseosms {
        width: 100%;
        max-width: 36%;
    }

    .brdrprnt {
        width: 100%;
        max-width: 40%;
    }

    .brdrbrand {
        width: 100%;
        max-width: 40%;
    }

    /* .infoflx {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    } */

    .groupdiv {
        padding-left: 5px;
    }
}

@media(max-width:575px) {

    .prdrtflxdivmain {
        flex-wrap: nowrap;
        overflow-y: auto;
    }

    .getdirectionbtn {
        background-color: #DEDEDE;
        color: #000;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .getdirectionbtn i {
        display: block;
        color: #000;
        margin-left: 5px;
        font-size: 16px;
    }

    .mainsavecontact {
        flex-wrap: wrap;
    }

    .brdrprdctdiv {
        min-width: 60%;
        max-width: 40%;
    }

    .txtdear {
        color: #010203;
        font-Size: 12px;
        max-width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .neoobg .likespinerdivflx,
    .membrinvit .likespinerdivflx {
        justify-content: space-between;
    }

    .likespinerdivflx {
        justify-content: flex-start;
        width: 100%;
        padding: 10px 0px;
    }

    .imgmain {
        width: 82px;
        height: 85px;
    }

    .imgbrdr {
        width: 89px;
        height: 90px;
    }

    .bggradient {
        /* background-image: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1));
        padding: 90px 0px; */
        height: 200px;
    }

    .dtlsprflnmmph {
        margin-top: 24px;
    }

    .whatsppimg,
    .callimg {
        width: 35px;
        height: 33px;
    }

    .callimg {
        order: 5;
        /* display: block; */
    }

    .likespiner {
        padding: 5px;
    }

    .img_profileflx {
        margin-left: 0px;
    }

    .infoflx {
        padding: 10px 10px;
    }

    .rctdivimg {
        width: 80px;
        height: 80px;
    }

    .bgsocial {
        width: 31px;
        height: 32px;
        font-size: 16px;
    }

    .socialdivflx {
        padding: 10px 0px;
    }

    .infolabbrdr {
        margin: 10px 20px;
    }

    .envelopdiv {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .brdrdivdtls:nth-child(3) {
        width: 100%;
        max-width: 100%;
    }

    .brdrdivdtls {
        width: 100%;
        max-width: 100%;
        text-align: left;
        padding: 0;
        border: none;
        margin-bottom: 6px;
    }

    .envelopdiv {
        text-align: left;
        padding: 0;
        border: none;
        margin-bottom: 6px;
    }

    .dibmailflx {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .divloctin {
        border: none;
        padding: 0;
    }

    .txtdtlmn i {
        font-size: 17px;
    }

    .cntncttxt {
        color: #010203;
        font-Size: 18px;
        font-family: 'Inter', sans-serif;
        font-Weight: 400;
        line-Height: 22px;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrmaindesign:nth-child(3) {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrseo {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrseosms {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrprnt {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrbrand {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

}

@media(max-width:480px) {

    .pdfflxDiv {
        width: 100%;
    }

    .cntncttxt {
        font-size: 16px;
    }

    .savecontactbtn {
        padding: 5px 15px;
        font-Size: 12px;
    }

    .imgmain {
        width: 75px;
        height: 77px;
    }

    .imgbrdr {
        width: 84px;
        height: 85px;
    }

    .profiledtlstxt {
        font-Size: 17px;
        line-Height: 23px;
    }

    .prodtlmarkettxt {
        font-Size: 12px;
        line-Height: 16px;
    }

    .ocentxt {
        font-Size: 20px;
        line-Height: 23px;
        padding-bottom: 0px;
    }

    .rctdivimg {
        width: 75px;
        height: 72px;
    }

    .dbytxtp {
        font-Size: 12px;
        line-Height: 15px;
    }

    .brdrdivdtls {
        text-align: left;
    }

    /* .bggradient {
        background-image: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1));
        padding: 50px 0px;
    } */

    .brdrmaindesign {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrseo {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrseosms {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrprnt {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrbrand {
        font-Size: 14px;
        padding: 5px;
    }
}

@media(max-width:420px) {
    .rctdivimg {
        width: 65px;
        height: 60px;
    }

    .ocentxt {
        font-Size: 13px;
        line-Height: 16px;
        padding-bottom: 0px;
    }

    .infoflx {
        padding: 7px 0px;
    }

    .pcndgtxt {
        font-Size: 13px;
        line-Height: 21px;
    }

    .dbytxtp {
        font-Size: 10px;
        line-Height: 15px;
    }

    .txtdtlmn {
        font-Size: 12px;
        line-Height: 12px;
    }

    /* .envelopdiv {
        text-align: left;
        padding: 5px;
    } */

    /* .brdrmaindesign {
        width: 100%;
        max-width: 100%;
    } */

}

.shredivflx {
    background-color: #009E60;
    padding: 10px 17px;
    display: flex;
    justify-content: flex-start;
    gap: 3%;
    align-items: center;
    color: #fff;
    font-size: 14px;
    border: 1px solid #009E60;
    border-radius: 10px;
    transition: all 0.5s ease;
}

.shredivflx i {
    color: #fff;
    margin-left: 5px;
}

.shredivflx:hover {
    border: 1px solid #009E60;
    color: #009E60;
    background-color: #fff;
}

.shredivflx:hover i {
    color: #009E60;
}

.quckcntntdiv {
    border: 2px solid #DBDEFE;
    margin: 15px 20px 0px;
    border-radius: 10px;
    padding: 10px 15px;
}

.cntghjflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.crtdivtct {
    color: #010203;
    font-Size: 28px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 32px;
}

.updgttxt {
    color: #000;
    border: none;
    background: transparent;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-Height: 20px;
}

.updgttxt:hover {
    color: #000;
}

.inptfgfrmdiv {
    width: 100%;
    border-radius: 5px;
}

.inptfgfrmdiv input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0px;
    border: 1px solid #dbdefe;
}

.inptfgfrmdiv input:focus {
    outline: 0;
}

.svaecntcydiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 2%;
    margin: 15px 0px;
}

.txtplt {
    color: #010203;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-height: 20px;
    background: transparent;
    border: none;
    outline: 0;
}

.txtplt:hover {
    color: #010203;
}

.btnsvsnd {
    color: #fff;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-height: 22px;
    background-color: #009E60;
    border: none;
    outline: 0;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.btnsvsnd:hover {
    color: #fff;
    background-color: #7f86e9;
}

.vdoighh {
    width: 100%;
    padding-top: 25px;
    height: 415px;
  }
.vdoighh .ReactPlayerdiv{
    width: 100% !important;
    height: 100% !important;
  }

.vdoighh iframe {
    width: 100%;
}

@media(max-width:767px) {
    .cntghjflx {
        flex-wrap: wrap;
    }

    .crtdivtct {
        font-Size: 20px;
        line-Height: 24px;
    }

    .txtplt {
        font-Size: 14px;
        font-Weight: 600;
        line-height: 17px;
    }

    .btnsvsnd {
        color: #fff;
        font-Size: 14px;
        line-height: 20px;
        background-color: #009E60;
        padding: 7px 10px;
        border-radius: 10px;
    }

    .svaecntcydiv {
        justify-content: space-between;
    }
}

@media (max-width: 575px) {

    .shredivflx {
        order: 3;
        Width: 80px;
        Height: 30px;
        font-size: 13px;
    }

    .shredivflx i {
        font-size: 13px;
    }

    .whatsppimg {
        order: 4;
    }

    .cntghjflx {
        /* flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; */
        column-gap: 15px;
        row-gap: 5px;
    }

    .crtdivtct {
        font-Size: 14px;
    }

    .updgttxt {
        font-size: 12px;
    }

    .inptfgfrmdiv input {
        width: 100%;
        padding: 5px 8px;
        font-size: 14px;
    }
}

@media(max-width:480px) {
    .svaecntcydiv {
        flex-wrap: wrap;
    }

    .quickContctBtn {
        width: 100%;
        margin: 10px 0px;
    }

    .btnsvsnd {
        margin: 10px 0px 0px 0px;
    }
}

@media(max-width:420px) {
    .crtdivtct {
        font-Size: 15px;
        line-Height: 21px;
    }
}