.btn-color {
    background-color: #3973dd;
    color: #fff;
    transition: background-color 0.3s ease;
}

.btn-color:hover {
    background-color: #6ca745;
    color: #000000;
}

.profile-image-pic {
    height: 200px;
    width: 200px;
    object-fit: cover;
}

.cardbody-color {
    background-color: #ebf2fa;
}

.position-absolute {
    position: absolute;
}

.end-0 {
    right: 0;
}

.top-50 {
    top: 50%;
}

.translate-middle-y {
    transform: translateY(-50%);
}

.password-input-container {
    position: relative;
}

.toggle-password {
    background: none;
    border: none;
    color: #4500b4;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 0;
    margin: 0px;
    margin-right: 10px;
}
