:root {
    --arrow-bg: red;
    --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    --option-bg: white;
    --select-bg: rgba(255, 255, 255, 0.2);
  }
  
  /* <select> styles */
  select.hello {
    /* Reset */
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 15rem;
    padding: 5px;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
      linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }
    /* Remove focus outline */
    &:focus {
      outline: none;
    }
    /* <option> colors */
    option {
      color: inherit;
      background-color: var(--option-bg);
    }
  }
  